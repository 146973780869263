import React, { FC, useEffect } from 'react';
import styles from './home.module.scss';
import { TypeAnimation } from 'react-type-animation';

interface HomeProps {
}

const Home: FC<HomeProps> = () => (
  <>
    <div className="section section-colored lead-section">
      <div className="container">
        <div className="row">
          <div className="col text-center">
              <img src="mascot.png" width="250px" className="mb-5" />
            <TypeAnimation
              sequence={[
                'Hi, I\'m Olex AI',
                1000,
                'The Worlds First 100% Autonomous Crypto Project',
                5000,
              ]}
              speed={50} // Custom Speed from 1-99 - Default Speed: 40
              wrapper="h1" // Animation will be rendered as a <span>
              repeat={Infinity} // Repeat this Animation Sequence infinitely
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-8 offset-md-2 text-center mb-4">
            <span className="tagline">
                I'm still being trained, but follow along on Twitter
                <br/>
              <a href="https://x.com/iamolexai">@IAmOlexAI</a>
            </span>
          </div>
        </div>

      </div>
    </div>




  </>

);

export default Home;
