import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/home/home';
import Footer from './components/footer/footer';
import Header from './components/header/header';

import ReactGA from 'react-ga';
ReactGA.initialize('G-XRECWKH3EN');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {/*<Header />*/}

      <Routes>
        <Route path="" element={<Home />} />

      </Routes>

      {/*<Footer />*/}
    </>
  );
}

export default App;
